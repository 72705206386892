import React, { useEffect, useState } from "react";
import BackgroundImage from "./CloudinaryImage";

const Banner = ({ filename, children, bgClasses = "bg-black bg-opacity-25" }) => {
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        const elHeight = document.getElementById("header")?.clientHeight;
        setHeaderHeight(elHeight);

        // Detectamos cambio en la medida del navegador
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleResize = e => {
        const elHeight = document.getElementById("header")?.clientHeight;
        setHeaderHeight(elHeight);
    };

    return (
        <>
            <BackgroundImage
                filename={filename}
                className=" bg-cover bg-center h-screen"
                style={{ height: `calc(100vh - ${headerHeight}px)` }}
            >
                <div className={`flex h-full ${bgClasses ? bgClasses : ""}`}>
                    <div className="container flex">
                        <div className="my-auto">{children}</div>
                    </div>
                </div>
            </BackgroundImage>
        </>
    );
};

export default Banner;
