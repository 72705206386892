import React from "react";
import Banner from "@components/common/Banner";
import Logo from "@components/common/Logo";
import { Link } from "gatsby";

const UserLayout = ({ children, image }) => {
    return (
        <main className="font-montserrat">
            <div className={styles.grid}>
                <div className={styles.left}>{children}</div>
                <div className={styles.right}>
                    <Banner filename={image} bgClasses="bg-ccyan-dark bg-opacity-75">
                        <Link to="/" title="iOpos - Inicio">
                            <Logo className="duration-300 w-32 mx-auto" type="white" />
                        </Link>

                        <div className="w-full h-full pt-10">
                            <h1 className="text-white font-bold text-center text-5xl leading-tight">
                                Academia de Oposiciones a Policía
                            </h1>
                            <h2 className="text-center text-white text-2xl mx-10">
                                Prepárate en tiempo récord. Con la máxima calidad y garantía de
                                éxito. Tu oposición a Mossos d'Esquadra, Guàrdia Urbana de Barcelona
                                y Policías Locales.
                            </h2>
                        </div>
                    </Banner>
                </div>
            </div>
        </main>
    );
};

const styles = {
    grid: "min-h-screen flex flex-col lg:flex-row",
    left: "bg-white px-10 md:px-20 lg:px-36 py-16 w-full lg:max-w-2xl",
    right: "bg-ccyan-dark hidden lg:block relative lg:w-full",
};

export default UserLayout;
