import React, { useState, useRef } from "react";
import PasswordStrengthBar from "react-password-strength-bar";

const InputObject = ({
    value,
    setValue,
    id,
    name,
    type,
    required,
    placeholder,
    icon: Icon,
    tabIndex,
    pattern,
    comment,
    reference,
    title,
    showStrengthBar = false,
    minLength = 6,
}) => {
    const [focus, setFocus] = useState(false);
    const inputRef = useRef(null);

    return (
        <div>
            <div
                className={styles.inputWrapper(focus)}
                onClick={() => inputRef.current.focus()}
                onKeyDown={() => inputRef.current.focus()}
                onFocus={() => inputRef.current.focus()}
                onBlur={() => setFocus(false)}
                role="textbox"
                tabIndex={tabIndex}
                ref={reference}
            >
                {title && <span className={styles.inputTitle(focus)}>{title}</span>}
                <input
                    ref={inputRef}
                    type={type}
                    id={id}
                    name={name}
                    className={styles.input}
                    required={required}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                    pattern={pattern}
                />

                <Icon className={styles.inputIcon} />
            </div>

            {showStrengthBar && (
                <PasswordStrengthBar
                    password={value}
                    minLength={minLength || 6}
                    className="mt-3"
                    scoreWords={["débil", "débil", "correcto", "bueno", "fuerte"]}
                    shortScoreWord={"demasiado corto"}
                />
            )}

            {comment && <div className={styles.comment}>{comment}</div>}
        </div>
    );
};

const styles = {
    inputWrapper: focus =>
        `border-2 rounded duration-300 px-5 py-3 flex items-center justify-between outline-none outline:border-ccyan gap-5 cursor-text relative ${
            focus ? "border-ccyan" : "border-gray-200"
        }`,
    input: "text-sm w-full focus:outline-none",
    inputTitle: focus =>
        `text-xs absolute top-0 -mt-3 left-5 z-[1] px-3 bg-white duration-300 ${
            focus ? "text-ccyan-dark" : "text-gray-500"
        }`,
    inputIcon: "w-6 h-6 text-gray-500",
    comment: "text-xs text-ccyan text-right mt-1 italic",
};

export default InputObject;
