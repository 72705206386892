import React, { useState, useEffect } from "react";
import Seo from "@components/common/Seo";
import Layout from "@components/layouts/UserLayout";
import { Link } from "gatsby";
import { SiMaildotru } from "react-icons/si";
import { AiOutlineLoading } from "react-icons/ai";
import { toast } from "react-toastify";
import useAuth from "@components/hooks/useAuth";
import InputObject from "@components/common/InputObject";
import { navigate } from "gatsby";

const Component = () => {
    const { forgot, loading } = useAuth();
    const [email, setEmail] = useState("");
    const [forgotError, setForgotError] = useState(null);

    useEffect(() => {
        if (process.env.GATSBY_AUTH_ENABLED !== "true") {
            navigate("/");
        }
    }, []);

    const onSubmit = async e => {
        e && e.preventDefault();

        if (loading) {
            return;
        }

        const success = await forgot({
            email,
        });

        if (success) {
            setEmail("");

            toast.success(
                "Se ha enviado un correo electrónico a tu cuenta con las instrucciones para recuperar tu contraseña.",
                {
                    position: "bottom-center",
                    closeOnClick: true,
                    pauseOnHover: true,
                    autoClose: 2000,
                },
            );
        } else {
            setForgotError(
                "No se pudo enviar el correo electrónico de recuperación. Es posible que la dirección de correo no esté registrada a ningún usuario.",
            );
        }
    };

    return (
        <>
            <Seo title="Recuperar cuenta de usuario" />
            <Layout image={"bgs/bgAcademy5"}>
                <div className="flex gap-5 justify-between">
                    <div className="-mt-6 text-gray-500 text-xs">
                        ¿Tienes ya una cuenta?{" "}
                        <Link
                            className="text-ccyan hover:border-ccyan border-b border-transparent duration-300"
                            to="/login"
                        >
                            Inicia sesión
                        </Link>
                    </div>

                    <div className="-mt-6 text-gray-500 text-xs text-right">
                        ¿No tienes una cuenta?{" "}
                        <Link
                            className="text-ccyan hover:border-ccyan border-b border-transparent duration-300"
                            to="/crear-cuenta"
                        >
                            Regístrate ahora
                        </Link>
                    </div>
                </div>

                <div className="flex h-full">
                    <div className="my-auto">
                        <h1 className={styles.title}>Recuperar cuenta de usuario</h1>
                        <h2 className={styles.subtitle}>
                            Anim irure amet eiusmod occaecat excepteur ipsum ut Lorem. Duis sit quis
                            est cupidatat. Cillum sunt id non consequat dolor est officia enim
                            reprehenderit nisi est. Minim nisi quis deserunt.
                        </h2>

                        <form className={styles.form} onSubmit={onSubmit}>
                            <InputObject
                                title="Correo electrónico"
                                type="email"
                                id="email"
                                name="email"
                                required={true}
                                placeholder="Dirección de correo electrónico"
                                value={email}
                                setValue={setEmail}
                                icon={SiMaildotru}
                                tabIndex="0"
                            />

                            <div>
                                <button className={styles.actionButton(loading)} disabled={loading}>
                                    {loading && <AiOutlineLoading className={styles.loadingIcon} />}
                                    <span>Recuperar cuenta</span>
                                </button>

                                <div className={styles.forgotError(forgotError)}>
                                    {forgotError || ""}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Layout>
        </>
    );
};

const styles = {
    title: "text-3xl lg:text-4xl font-bold text-gray-800",
    subtitle: "text-gray-600 text-sm mt-2 md:mt-0",
    form: "mt-10 flex flex-col space-y-10",
    loadingIcon: "text-center w-4 h-4 animate-spin duration-300",
    actionButton: loading =>
        `text-white text-center text-sm px-5 py-3 text-center w-full bg-ccyan rounded hover:bg-ccyan-dark duration-300 flex items-center justify-center space-x-5 ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
        }`,
    forgotError: error =>
        `mt-5 text-xs text-red-600 text-center duration-300 ${error ? "opacity-100" : "opacity-0"}`,
};

export default Component;
